import React from 'react'

import { Box } from 'components'
import SEO from 'components/Seo'
import Header from 'components/Header'
import Footer from 'components/Footer'
import CookieConsent from 'components/CookieConsent'

import { BAMBUS_COOKIE_NO_TRACK, BAMBUS_COOKIE_TRACK_LEVEL } from 'utils'

import { useLocation } from '@reach/router'
import { isBrowser } from 'utils'
import { useSharedState } from 'context/sharedState'
import loadable from '@loadable/component'

import 'fonts/font.css'

const StandardLeadModal = loadable(() => import('components/Calculator/Lead'))
const TeilverkaufLeadModal = loadable(() =>
  import('components/Calculator/TeilverkaufLeadSinglePage')
)
const ReducedLeadModal = loadable(() => import('components/ReducedLeadModal'))

const BrochureModal = loadable(() => import('components/BrochureSignupModal'))

const LeadModalManager = () => {
  const [state] = useSharedState()

  // Standard modal related state
  const [isStandardModalShown, setIsStandardModalShown] = React.useState(false)
  const [isOpenInCallbackMode, setIsOpenInCallbackMode] = React.useState(false)
  const [isOpenInTeilverkaufMode, setIsOpenInTeilverkaufMode] =
    React.useState(false)
  const [isOpenInLockedOfferMode, setIsOpenInLockedOfferMode] =
    React.useState(false)

  // Reduced modal related state
  const [isReducedModalShown, setIsReducedModalShown] = React.useState(false)

  // Brochure modal related state
  const [isBrochureModalShown, setIsBrochureModalShown] = React.useState(false)

  // Pieces of data that can be sent to the modal
  const [signupReason, setSignupReason] = React.useState('')
  const [formMetadataLSKey, setFormMetadataLSKey] = React.useState('')
  const [amount, setAmount] = React.useState('')
  const [serviceDimension, setServiceDimension] = React.useState(null)
  const [propertyWorth, setPropertyWorth] = React.useState(null)
  const [payout, setPayout] = React.useState(null)

  const loc = useLocation()

  React.useEffect(() => {
    const shouldRenderStandardModal = loc.hash.includes('#anmelden')
    setIsStandardModalShown(shouldRenderStandardModal)
    setIsOpenInLockedOfferMode(loc.hash.includes('#anmeldenLockedOfferMode'))
    setIsOpenInCallbackMode(loc.hash.includes('#anmeldenCallbackMode'))
    setIsOpenInTeilverkaufMode(loc.hash.includes('TeilverkaufModal'))

    const shouldRenderReducedModal = loc.hash.includes('#reducedAnmelden')
    setIsReducedModalShown(shouldRenderReducedModal)

    const shouldRenderBrochureModal = loc.hash.includes('#brochureAnmelden')
    setIsBrochureModalShown(shouldRenderBrochureModal)

    const searchParams = new URLSearchParams(loc.search)
    if (searchParams.has('lead_signup_reason')) {
      setSignupReason(searchParams.get('lead_signup_reason'))
    }
    if (searchParams.has('lead_form_metadata_ls_key')) {
      setFormMetadataLSKey(searchParams.get('lead_form_metadata_ls_key'))
    }
    if (searchParams.has('lead_amount')) {
      setAmount(searchParams.get('lead_amount'))
    }
    if (searchParams.has('service_dimension')) {
      setServiceDimension(searchParams.get('service_dimension'))
    }
    if (searchParams.has('property_worth')) {
      setPropertyWorth(searchParams.get('property_worth'))
    }
    if (searchParams.has('payout')) {
      setPayout(searchParams.get('payout'))
    }

    if (
      (shouldRenderStandardModal || shouldRenderReducedModal) &&
      !('trackEvent' in state)
    ) {
      window.dataLayer?.push({
        event: 'generic_cta_start',
      })
      window._paq?.push([
        'trackEvent',
        'generic_cta_start',
        'generic_cta_start',
      ])
    }
  }, [loc])

  const handleCloseModal = (modalType = 'standard') => {
    if (modalType === 'standard') {
      setIsStandardModalShown(false)
      setIsOpenInCallbackMode(false)
    }
    if (modalType === 'reduced') {
      setIsReducedModalShown(false)
    }
    if (modalType === 'brochure') {
      setIsBrochureModalShown(false)
    }

    // TODO: Is this check really necessary here?
    if (isBrowser) {
      // if we have lead related data in the URL as query parameters, remove them
      const searchParams = new URLSearchParams(loc.search)
      if (searchParams.has('lead_signup_reason')) {
        setSignupReason('')
        searchParams.delete('lead_signup_reason')
      }
      if (searchParams.has('lead_form_metadata_ls_key')) {
        setFormMetadataLSKey('')
        searchParams.delete('lead_form_metadata_ls_key')
      }
      if (searchParams.has('lead_amount')) {
        setAmount('')
        searchParams.delete('lead_amount')
      }
      if (searchParams.has('service_dimension')) {
        setServiceDimension(null)
        searchParams.delete('service_dimension')
      }
      if (searchParams.has('property_worth')) {
        setPropertyWorth(null)
        searchParams.delete('property_worth')
      }
      if (searchParams.has('payout')) {
        setPayout(null)
        searchParams.delete('payout')
      }
      if (searchParams.toString() !== '') {
        window.history.replaceState(
          null,
          null,
          `${loc.pathname}?${searchParams.toString()}`
        )
      } else {
        window.history.replaceState(null, null, `${loc.pathname}`)
      }
    }
  }

  if (isStandardModalShown && !isOpenInTeilverkaufMode) {
    return (
      <StandardLeadModal
        onClose={() => {
          handleCloseModal('standard')
        }}
        openInCallbackMode={isOpenInCallbackMode}
        teilverkaufMode={isOpenInTeilverkaufMode}
        isInLockedOfferMode={isOpenInLockedOfferMode}
        signupReason={signupReason}
        formMetadataLSKey={formMetadataLSKey}
        amount={amount}
        serviceDimension={serviceDimension}
      />
    )
  }

  if (isStandardModalShown && isOpenInTeilverkaufMode) {
    return (
      <TeilverkaufLeadModal
        onClose={() => {
          handleCloseModal('standard')
        }}
        signupReason={signupReason}
        openInCallbackMode={isOpenInCallbackMode}
        formMetadataLSKey={formMetadataLSKey}
        amount={amount}
        propertyWorth={propertyWorth}
        payout={payout}
      />
    )
  }

  if (isReducedModalShown) {
    return (
      <ReducedLeadModal
        onClose={() => {
          handleCloseModal('reduced')
        }}
        signupReason={signupReason}
      />
    )
  }

  if (isBrochureModalShown) {
    return (
      <BrochureModal
        onClose={() => {
          handleCloseModal('brochure')
        }}
      />
    )
  }

  return null
}

const Main = (props) => (
  <Box sx={{ flexGrow: 1, pt: [64, null, null, 128] }} {...props} />
)

const Layout = (props) => {
  const { seo = { title: null }, children } = props

  return (
    <>
      <SEO {...seo} />
      <Header />
      <Main>{children}</Main>
      <Footer />
      <LeadModalManager />
      <CookieConsent
        noTrackCookieName={BAMBUS_COOKIE_NO_TRACK}
        trackLevelCookieName={BAMBUS_COOKIE_TRACK_LEVEL}
        onAccept={() => {
          window._paq && window._paq.push(['rememberCookieConsentGiven'])
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        }}
        onPartialAccept={() => {
          // window._paq && window._paq.push(['rememberCookieConsentGiven'])
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        }}
        onReject={() => {
          window._paq && window._paq.push(['forgetCookieConsentGiven'])
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        }}
      />
    </>
  )
}

export default Layout
