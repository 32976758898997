import React, { useEffect, useRef, useState } from 'react'
import { Link as RouterLink } from 'gatsby'
import { X as Close, CheckCircle, Check } from 'icons'
import Cookies from 'js-cookie'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { Box, Text, Button, Flex, Heading, Icon, Link } from 'components'

import useKeyPressEvent from 'hooks/useKeyPressEvent'

import { CONSENT_COOKIE_DOMAIN } from 'config'

function noop() {}

const CookieConsent = ({
  noTrackCookieName = '',
  trackLevelCookieName = '',
  onAccept = noop,
  onPartialAccept = noop,
  onReject = noop,
  onClose = noop,
}) => {
  const ref = useRef()
  const [open, setOpen] = useState(false)
  useKeyPressEvent('Escape', onClose)

  useEffect(() => {
    if (open && ref.current) {
      disableBodyScroll(ref.current, { allowTouchMove: false })
    } else {
      clearAllBodyScrollLocks()
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [open])

  const noTrackFromCookie = Cookies.get(noTrackCookieName)
  const trackLevelFromCookie = Cookies.get(trackLevelCookieName)

  const hasTrackCookies =
    noTrackFromCookie !== undefined || trackLevelFromCookie !== undefined

  useEffect(() => {
    if (!hasTrackCookies) {
      setOpen(true)
    }
  }, [hasTrackCookies])

  const TRACKING_LEVEL = {
    all: 'all',
    required: 'required',
  }

  const setNoTrackCookie = (allowTracking) => {
    Cookies.set(noTrackCookieName, allowTracking, {
      expires: 365,
      ...(CONSENT_COOKIE_DOMAIN && { domain: CONSENT_COOKIE_DOMAIN }),
    })
  }
  const setTrackLevelCookie = (level) =>
    Cookies.set(trackLevelCookieName, level, {
      expires: 365,
      ...(CONSENT_COOKIE_DOMAIN && { domain: CONSENT_COOKIE_DOMAIN }),
    })

  const handleAccept = () => {
    setNoTrackCookie(false)
    setTrackLevelCookie(TRACKING_LEVEL.all)
    onAccept()
  }

  const handlePartialAccept = () => {
    setNoTrackCookie(false)
    setTrackLevelCookie(TRACKING_LEVEL.required)
    onPartialAccept()
  }

  const handleReject = () => {
    setNoTrackCookie(true)
    onReject()
  }

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  return !open ? null : (
    <Box
      ref={ref}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 20000,
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          position: ['relative', null, 'fixed'],
          top: [null, null, '50%'],
          left: [null, null, '50%'],
          transform: [null, null, 'translate(-50%,-50%)'],
          bg: 'colorBlue100',
          color: 'white',
          borderRadius: 'default',
          width: [null, null, '100%'],
          maxWidth: 646,
          p: [4, 5],
          mx: [2, 'auto'],
          my: [2, 12, 0, null],
          zIndex: 1,
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Heading.H5 sx={{ color: 'white', flexGrow: 1 }}>
            Wir verwenden Cookies.
          </Heading.H5>

          <Box
            as="button"
            m={-2}
            p={2}
            flexShrink={0}
            sx={{
              cursor: 'pointer',
              background: 'none',
              border: 'none',
              appearance: 'none',
            }}
            onClick={handleClose}
          >
            <Icon source={Close} color="white" size={18} />
          </Box>
        </Flex>

        <Text sx={{ fontSize: 1, py: 5 }}>
          Diese Website verwendet Cookies, um bestimmte Funktionen und
          Analytics, um das Angebot zu verbessern. Indem Sie hier fortfahren,
          stimmen Sie der Nutzung von Cookies und Analytics gemäss unserer{' '}
          <Link as={RouterLink} to="/datenschutz">
            Datenschutzerklärung
          </Link>{' '}
          zu.
        </Text>

        <Flex sx={{ mx: -3, my: -2, flexWrap: ['wrap', null, 'nowrap'] }}>
          <Button
            as="button"
            variant="default"
            onClick={handleReject}
            sx={{
              borderRadius: 'default',
              flexGrow: 1,
              alignItems: 'center',
              px: 0,
              mx: 3,
              my: 2,
              whiteSpace: 'nowrap',
              width: ['100%', 'auto'],
            }}
          >
            <Icon
              source={Close}
              color="red"
              size={14}
              sx={{ display: 'flex', mr: 2 }}
            />
            Nicht akzeptieren
          </Button>
          <Button
            as="button"
            variant="default"
            onClick={handlePartialAccept}
            sx={{
              borderRadius: 'default',
              flexGrow: 1,
              alignItems: 'center',
              mx: 3,
              my: 2,
              whiteSpace: 'nowrap',
              width: ['100%', 'auto'],
              bg: 'blue',
            }}
          >
            <Icon
              source={CheckCircle}
              color="white"
              size={14}
              sx={{ display: 'flex', mr: 2 }}
            />
            Nur erforderliche
          </Button>
          <Button
            as="button"
            variant="default"
            onClick={handleAccept}
            sx={{
              borderRadius: 'default',
              flexGrow: 1,
              alignItems: 'center',
              mx: 3,
              my: 2,
              whiteSpace: 'nowrap',
              width: ['100%', 'auto'],
              bg: 'green',
            }}
          >
            <Icon
              source={Check}
              color="white"
              size={14}
              sx={{ display: 'flex', mr: 2 }}
            />
            Alle akzeptieren
          </Button>
        </Flex>
      </Box>
      <Box
        sx={{
          bg: 'colorBlue2',
          opacity: 0.9,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
      />
    </Box>
  )
}

export default CookieConsent
